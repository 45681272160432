@import "src/style/variable";
@import "src/style/typograph";

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $text;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  float: right;
  position: relative;
  padding: 100px;
}

.sectionName {
  display: none;
  user-select: none;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;

  & > h4 {
    user-select: none;
    @include names;
  }
}

.mainText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80vh;
  height: 100%;
  overflow: hidden;

  & > p {
    user-select: none;
    @include description;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    width: 100%;
    padding: 60px;
  }

  .sectionName {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section;
  }

  .textWrapper > h4 {
    @include names(32px);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 40px;
  }

  .sectionName {
    @include section(30px);
  }

  .textWrapper > h4 {
    @include names(20px);
  }

  .mainText > p {
    @include description(12px);
  }
}
