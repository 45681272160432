@import "src/style/variable";
@import "src/style/typograph";

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  color: $text;
  height: 100%;
  float: right;
  position: relative;
}

.sectionName {
  display: none;
  user-select: none;
}

.textWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;

  & > p {
    user-select: none;
    @include title;
  }
}

@media only screen and (min-width: 1200px) {
  .content {
    padding: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    padding: 80px;
  }

  .textWrapper > p {
    @include title(24px);
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    width: 100%;
  }

  .sectionName {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section;
  }

  .textWrapper > p {
    @include title(20px);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 50px;
  }

  .sectionName {
    @include section(30px);
  }

  .textWrapper > p {
    @include title(15px);
  }
}

@media only screen and (max-width: 480px) {
  .content {
    padding: 30px;
  }

  .sectionName {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section(28px);
  }

  .textWrapper > p {
    @include title(12px);
  }
}