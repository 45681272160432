@import "src/style/variable";
@import "src/style/typograph";


.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: $background;
}

.content {
  display: flex;
  width: 80%;
  height: 80%;
  float: right;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title {
  z-index: 1;
  color: $text;
}
.canvas{
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.wave {
  display: flex;
  width: 100%;
  position: absolute;
}

.wave > svg {
  width: 100%;
}

.title {
  user-select: none;
}

@media only screen and (min-width: 1440px) {
  .title {
    max-width: 900px;
    @include slogan(130px)
  }

}

@media only screen and (max-width: 1440px) {
  .title {
    max-width: 650px;
    @include slogan(100px)
  }
}

@media only screen and (min-width: 1024px) {
  .wave {
    top: 40%;
  }
}

@media only screen and (max-width: 1024px) {
  .title {
    max-width: 550px;
    @include slogan(90px)
  }
  .wave {
    top: 30%;
  }
}

@media only screen and (max-width: 768px) {
  .title {
    @include slogan(70px);
    max-width: 450px;
  }
}

@media only screen and (max-width: 690px) {
  .title {
    @include slogan(50px);
    max-width: 350px;
  }
}

@media only screen and (max-width: 425px) {
  .title {
    @include slogan(30px);
    max-width: 250px;
  }
}
