@import "src/style/variable";
@import "src/style/typograph";


.aside{
  height: 100vh;
  min-width: 20%;
  position: fixed;
  z-index: 1;
  top: 160px;
  left:50px;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.navList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;
  height: 345px;
}


.intellzyIcon > svg {
  width: 120px;
  height: 50px;
  transition: 0.5s;
}

.navItem {
  width: max-content;
  list-style: none;
  @include important;
  transition: 0.5s;
  user-select: none;
  cursor: pointer;
}

.focusedNavItem {
  list-style: none;
  cursor: pointer;
  transition: 0.5s;
  @include section;
}

.welcome {
  .intellzyIcon > svg {
    width: 180px;
    height: 60px;
    transition: 0.5s;
  }
  transition: 0.5s;
  color: $non-active-text;
}

.aboutUs {
  color: $background;
  .intellzyIcon > svg path{
    fill: $background;
  }
}

.projects {
  color: $non-active-text;
}

.services {
  color: $non-active-text;
}

.courses {
  .intellzyIcon > svg path {
    fill: $background;
  }
  color: $background;
}

.team {
  color: $non-active-text;
}

.contactUs {
  color: $non-active-text;
}

.activeTitle {
  color: $text;
}