@import "src/style/variable";
@import "src/style/typograph";

.footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 130px;
  background-color: $background;

  @media only screen and (max-width: 790px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
}

.contactInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
  height: 100%;

  @media only screen and (max-width: 690px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.email,
.number {
  @include description;
  color: $highlights;
  user-select: none;

  @media only screen and (max-width: 768px) {
    @include description(18px);
  }

  @media only screen and (max-width: 690px) {
    text-align: center;
    width: 100%;
    @include description(12px);
  }
}

.socialMedias {
  display: flex;
  align-items: center;
  max-width: 250px;
  height: 100%;
  justify-content: space-between;
  gap: 30px;

  @media only screen and (max-width: 690px) {
    max-width: none;
    justify-content: center;
    gap: 15px;
  }
}

.icons > svg {
  width: 30px;
  height: 30px;
}