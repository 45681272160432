@import "src/style/variable";
@import "src/style/typograph";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: $background;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 90px;
  float: right;
  position: relative;
}

.sectionName {
  display: none;
}

.services {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.consulting,
.development {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $text;
}

.consulting {
  height: max-content;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  @include title;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: 0.5s;
}

.head > span:hover {
  cursor: pointer;
}

.description {
  padding-right: 105px;
  @include description(24px);
}

.line {
  border: 1px solid $text;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .content {
    padding: 80px;
  }
  .description{
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    width: 100%;
  }

  .head {
    @include title(28px);
  }

  .description {
    padding-right: 80px;
    @include description(16px);
  }

  .sectionName {
    display: flex;
    position: absolute;
    color: $text;
    top: 20px;
    left: 20px;
    @include section;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 50px;
  }

  .sectionName {
    @include section(30px);
  }

  .head > p {
    @include title(20px);
  }

  .description {
    padding-right: 60px;
    @include description(14px);
  }
  .arrows > svg{
    width: 24px;
    height: 13px;
  }
}

@media only screen and (max-width: 480px) {
  .content {
    padding: 15px;
  }

  .head > p {
    @include title(16px);
  }

  .description {
    padding-right: 40px;
    @include description(12px);
  }

  .sectionName {
    @include section(30px);
  }
  .arrows > svg{
    width: 22px;
    height: 11px;
  }
}
