@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@400;700&family=Rye&display=swap');

@font-face {
  font-family: 'Magistral';
  src: local('Magistral Bold'), local('Magistral-Bold'),
  url('./fonts/Magistral-Bold.woff2') format('woff2'),
  url('./fonts/Magistral-Bold.woff') format('woff'),
  url('./fonts/Magistral-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral Medium'), local('Magistral-Medium'),
  url('./fonts/Magistral-Medium.woff2') format('woff2'),
  url('./fonts/Magistral-Medium.woff') format('woff'),
  url('./fonts/Magistral-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Magistral';
  src: local('Magistral Book'), local('Magistral-Book'),
  url('./fonts/Magistral-Book.woff2') format('woff2'),
  url('./fonts/Magistral-Book.woff') format('woff'),
  url('./fonts/Magistral-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@mixin slogan($font-size: 100px) {
  font-family: 'Magistral', sans-serif;
  font-weight: normal;
  font-size: $font-size;
}

@mixin names($font-size: 64px) {
  font-family: 'Magistral', sans-serif;
  font-size: $font-size;
  font-weight: normal;
}

@mixin title($font-size: 40px) {
  font-family: 'Magistral', sans-serif;
  font-size: $font-size;
  font-weight: normal;
}

@mixin section($font-size: 48px) {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size;
  font-weight: bold;
}

@mixin selected($font-size: 36px) {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size;
  font-weight: bold;
}


@mixin important {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: bold;
}

@mixin description($font-size: 24px) {
  font-family: 'Roboto', sans-serif;
  font-size: $font-size;
  font-weight: 400;
}
