@import "src/style/variable";
@import "src/style/typograph";



::-webkit-scrollbar {
  display: none;
}

.main{
  display: flex;
  flex-direction: column;
}