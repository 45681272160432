@import 'src/style/variable';
@import 'src/style/typograph';

.wrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: $background;
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 100%;

  @media only screen and (min-width: 1440px) {
    padding: 74px 100px;
  }

  @media only screen and (max-width: 1440px) {
    padding: 54px 80px;
  }

  @media only screen and (max-width: 1220px) {
    padding: 25px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 100px;
  }

  @media only screen and (max-width: 570px) {
    width: 100%;
    padding: 100px 50px;
    height: 100%;
  }
}

.sectionName {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: flex;
    color: $text;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section;

    @media only screen and (max-width: 768px) {
      @include section(30px);
    }
  }
}

.workersWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.leftWorker,
.rightWorker {
  user-select: none;
  background-color: $background;
  display: flex;
  align-items: end;
}

.personInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 10px;
}

.leftWorker {

  .personInfo {
    flex-direction: column;
    right:40px;

    @media only screen and (max-width: 650px) {
      right: 50px;
    }
    @media only screen and (max-width: 440px) {
      right: 25px;
    }
  }
}

.rightWorker {
  flex-direction: row-reverse;

  .personInfo {
    align-items: end;
    left:40px;

    @media only screen and (max-width: 650px) {
      left: 50px;
    }

    @media only screen and (max-width: 440px) {
      left: 25px;
    }

  }
}


.name {
  @include names(64px);
  color: $highlights;

  @media only screen and (max-width: 1200px) {
    @include names(32px);
  }

  @media only screen and (max-width: 768px) {
    @include names(28px);
  }

  @media only screen and (max-width: 650px) {
    @include names(18px);
  }

  @media only screen and (max-width: 440px) {
    @include names(16px);
  }

  @media only screen and (max-width: 390px) {
    @include names(12px);
  }
}

.activity {
  @include description(24px);
  color: $text;

  @media only screen and (max-width: 768px) {
    @include description(16px);
  }

  @media only screen and (max-width: 650px) {
    @include description(12px);
  }

  @media only screen and (max-width:440px) {
    @include description(10px);
  }

  @media only screen and (max-width:390px) {
    @include names(8px);
  }

}

.image {
  width: 250px;
  height: 250px;
  border-radius: 40px;
  object-fit: cover;
  object-position: top;

    @media only screen and (max-width: 1440px) {
    width: 170px;
    height: 170px;
    border-radius: 35px;
  }

  @media only screen and (max-width: 1200px) {
    width: 180px;
    height: 180px;
    border-radius: 30px;
  }

  @media only screen and (max-width: 800px) {
    width: 150px;
    height: 150px;
    border-radius: 20px;
  }

  @media only screen and (max-width: 768px) {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }

  @media only screen and (max-width: 570px) {
    width: 100px;
    height: 100px;
    border-radius: 20px;
  }


  @media only screen and (max-width:440px) {
    width: 90px;
    height: 90px;
    border-radius: 15px;
  }

  @media only screen and (max-width:390px) {
    width: 70px;
    height: 70px;
    border-radius: 5px;
  }
}