@import "src/style/variable";
@import "src/style/typograph";

.wrapper {
  width: 100%;
  position: relative;
  height: calc(100vh - 130px);
  background-color: $background;
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-self:center;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  padding: 80px 80px 40px;
  float: right;
  overflow: hidden;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 80px 40px 40px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 60px 20px 20px;
  }
}

.inputs {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 45px;

  @media only screen and (max-height: 560px) {
    gap: 10px;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.error {
  user-select: none;
  color: $highlights;

  @media only screen and (max-height: 1440px) {
    @include description(24px);
  }

  @media only screen and (max-height: 768px) {
    @include description(16px);
  }

  @media only screen and (max-height: 590px) {
    @include description(10px);
  }
}

.sectionName {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: flex;
    color: $text;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section;


    @media only screen and (max-width: 768px) {
      @include section(30px);
    }
  }
}

.input,
.textarea {
  user-select: none;
  padding: 16px;
  width: 100%;
  color: $text;
  @include description;
  border: 1px solid $non-active-text;
  background-color: transparent;
  border-radius: 12px;

  @media only screen and (min-width: 1800px) {
    height: 100px;
    @include description(32px);
    border-radius: 20px;
  }

  @media only screen and (max-width: 425px) {
    padding: 8px;
    @include description(12px);
    border-radius: 8px;
  }
}

.textarea {
  min-height: 230px;
  white-space: nowrap;

  @media only screen and (max-width: 1800px) {
    min-height: 130px;
  }
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  user-select: none;
  width: 30%;
  max-width: 350px;
  padding: 8px;
  @include selected(28px);
  color: $highlights;
  align-self: end;
  border: 1px solid $highlights;
  background-color: $background;

  @media only screen and (min-width: 1800px) {
    border-radius: 15px;
    @include selected(24px);
  }

  @media only screen and (max-width: 768px) {
    border-radius: 10px;
    @include selected(18px);
  }

  @media only screen and (max-width: 425px) {
    border-radius: 7px;
    @include selected(10px);
  }

  &:active {
    background-color: $btnactive;
    color: $background;
  }
}