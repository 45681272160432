@import 'src/style/variable';
@import 'src/style/typograph';

.alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 15px;
  right: 15px;
  border-radius: 8px;
  border: 1px solid $text;
  z-index: 1;
  animation: fadeInOut 0.5s ease-in-out forwards;
  user-select: none;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.success {
  background-color: $successColor;
  color: $text;
  @include section(18px);
}

.error {
  background-color: $errorColor;
  color: $text;
  @include section(18px);
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .alert {
    width: 100px;
    padding: 10px;
  }

  .success {
    @include section(14px);
  }

  .error {
    @include section(14px);
  }
}

@media screen and (max-width: 768px) {
  .alert {
    width: 150px;
    padding: 10px;
  }

  .success {
    @include section(18px);
  }

  .error {
    @include section(18px);
  }
  .closeBtn{
    width: 25px;
    height: 25px;
  }
}

@media screen and (min-width: 768px) {
  .alert {
    width: 300px;
    padding: 15px;
  }

  .success {
    @include section(20px);
  }

  .error {
    @include section(20px);
  }

  .closeBtn{
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .alert {
    width: 400px;
    padding: 20px;
  }

  .success {
    @include section(24px);
  }

  .error {
    @include section(24px);
  }
  .closeBtn{
    width: 35px;
    height: 35px;
  }
}

@media screen and (min-width: 1600px) {
  .alert {
    width: 500px;
    padding: 25px;
  }

  .success {
    @include section(32px);
  }

  .error {
    @include section(32px);
  }
  .closeBtn{
    width: 50px;
    height: 50px;
  }
}