@import "src/style/variable";
@import "src/style/typograph";

.wrapper {
  width: 100vw;
  position: relative;
  height: 100vh;
  background-color: $highlights;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  height: 100%;
  padding: 80px 40px;
  gap: 100px;
  float: right;
  position: relative;
}

.sectionName {
  display: none;
  user-select: none;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;

  & > h1 {
    user-select: none;
    @include section;
  }
}

.mainText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80vh;
  height: 100%;
  overflow: hidden;

  & > p {
    user-select: none;
    @include description;
  }

  .mainText > p {
    @include description(16px);
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    padding: 80px 60px;
  }
  .mainText > p {
    @include description(18px);
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    width: 100%;
  }
  .sectionName {
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    @include section;
  }

  .mainText > p {
    @include description(16px);
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 100px 30px;
  }

  .sectionName {
    @include section(30px);
  }

  .textWrapper > h1 {
    @include section(36px);
  }

  .mainText > p {
    @include description(14px);
  }
}

@media only screen and (max-width: 480px) {
  .content {
    padding:  20px;
  }

  .sectionName {
    @include section(28px);
  }

  .textWrapper > h1 {
    @include section(24px);
  }

  .mainText > p {
    @include description(12px);
  }
}
